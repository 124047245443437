<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <b-img
          class="logo-loading"
          :src="$data.$themeConfig.app.appLogoImage"
          alt="logo"
        />
        <br>
        <b-spinner
          class="mr-1 mt-1 mb-1"
          label="Small Spinner"
        />
        <h2 class="mb-1">
          Por favor espere
        </h2>
        <p class="mb-2">
          En breve será redireccionado automáticamente.
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import { $themeConfig } from '@themeConfig'
import { BSpinner } from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'Loading',
  url_data: [],
  components: {
    BSpinner,
  },
  data() {
    return {
      $themeConfig,
      toroute: ' ',
    }
  },
  async created() {
    await this.storageTransferredParameters()
    let user = window.atob(store.state.auth.authUser)
    user=user.split('-');
    const pass = window.atob(store.state.auth.authPassword)
    await this.$store.dispatch('auth/login', {
      username: user[0],
      password: pass,
    }).then((response) => {
      this.getUserData().then(user => {
        this.getUserMenu().then(() => {
          this.getUserAccess()
          this.$router.push(this.toroute)
        })
      })
    })
  },
  methods: {
    async getUserData(id) {
      return this.$store.dispatch('auth/getUserData',id)
    },
    async getUserMenu() {
      return this.$store.dispatch('auth/getUserMenu',this)
    },
    getUserAccess() {
      return this.$store.dispatch('security/sendAcl', this.$router.options.routes)
    },
    storageTransferredParameters() {
      const {
        toroute, backurl, uparam, pparam,
      } = this.$route.query
      console.log(this.$route.query)
      this.toroute = toroute
      if (toroute && backurl && uparam && pparam) {
        store.commit('auth/SET_BACK_URL', backurl)
        store.commit('auth/SET_TO_URL', toroute)
        store.commit('auth/SET_AUTH_USER', uparam)
        store.commit('auth/SET_AUTH_PASSWORD', pparam)
      }
      return 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.logo-loading {
    height: 80px;
}
</style>
